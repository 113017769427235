<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <span>Edição de conexões</span>
        <br />
        <small
          >Conexão atual: <strong>{{ currentInfo.name }}</strong></small
        >
      </div>
      <div class="wrapper__card__content">
        <v-form>
          <div class="form">
            <z-input
              v-model="currentConnectionInfo.name"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              label="Nome"
              type="text"
            />

            <z-select
              v-model="currentConnectionInfo.errorBehaviour"
              label="Comportamento de erro"
              :items="errorBehaviourItems"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              itemText="name"
              itemValue="value"
            />

            <z-select
              v-if="currentInfo.typeConfig != 'csv'"
              v-model="currentConnectionInfo.cron"
              label="Frequência de atualização"
              :items="frequencies"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              itemText="name"
              itemValue="value"
            />
          </div>
        </v-form>

        <v-card class="py-1 px-5 mt-8" elevation="1">
          <v-form ref="dynamicForm">
            <div class="table">
              <div class="table__header mb-7">
                <div class="table__header__line">
                  <div>
                    <span class="text-header">Nome do campo</span>
                  </div>
                  <!-- 
                  <div class="hide text-center" style="width: 200px">
                    <span class="text-header">Origem</span>
                  </div> -->

                  <div class="hide text-center" style="width: 200px">
                    <span class="text-header">Tipo do campo</span>
                  </div>

                  <div class="hide text-center">
                    <span class="text-header">Formato do campo</span>
                  </div>

                  <div class="hide text-center">
                    <span class="text-header">Ação</span>
                  </div>
                </div>
              </div>

              <div class="table__body">
                <div
                  v-for="(item, i) in currentConnectionInfo.fields"
                  :key="i"
                  class="table__body__line"
                >
                  <div class="camp-name">
                    <span>{{ i }} </span>
                  </div>

                  <!--     <div class="new-name">
                    <z-input
                      v-model="item.new_name"
                      placeholder="Digite aqui"
                      :hide-details="true"
                      type="text"
                    />
                  </div> -->

                  <div class="d-flex camp-type">
                    <z-select
                      v-model="item.type"
                      :items="dataTypes"
                      itemText="name"
                      itemValue="value"
                      disabled
                      placeholder="Tipo do campo"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      :hide-details="true"
                      type="text"
                    />
                  </div>

                  <div style="width: 100px" class="camp-format">
                    <z-select
                      v-if="item.type === 'datetime'"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      :items="dateTimeFormats"
                      itemText="format"
                      v-model="item.format"
                      itemValue="format"
                      placeholder="Formato do campo"
                      :hide-details="true"
                      disabled
                    />
                    <z-select
                      v-else-if="item.type === 'date'"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      :items="dateFormats"
                      itemText="format"
                      v-model="item.format"
                      itemValue="format"
                      placeholder="Formato do campo"
                      :hide-details="true"
                      disabled
                    />
                  </div>

                  <div class="action">
                    <z-btn
                      width="100%"
                      small
                      text="Remover"
                      primary
                      color="#F65C5B"
                      :rounded="true"
                      style="color: white"
                      :disabled="mandatory.includes(i)"
                      @click="setFieldToRemove(i)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-form>

          <div class="table-actions d-flex justify-end mt-7 mb-2">
            <z-btn
              text="Adicionar campo"
              primary
              color="primary"
              :rounded="true"
              @click="newCampDialog = true"
            />
          </div>
        </v-card>

        <div class="actions d-flex align-center justify-center mt-6">
          <z-btn
            text="Atualizar informações"
            primary
            color="success"
            icon="$save"
            :rounded="true"
            :is-loading="submitLoading"
            @click="submitForm"
          />
        </div>
      </div>
    </div>

    <v-dialog v-model="newCampDialog" width="700">
      <NewCampModal
        v-if="newCampDialog"
        :key="newCampDialogKey"
        :fields-list-prop="fieldsList"
        :data-types-prop="dataTypes"
        :dateFormatsProp="dateFormats"
        :dateTimeFormatsProp="dateTimeFormats"
        @create="createNewField"
        @close="newCampDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="deleteFieldModal" width="600">
      <DeleteFieldModal
        v-if="deleteFieldModal"
        :name="currentFieldToRemove"
        @remove-field="removeField"
        @close="deleteFieldModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
const _ = require("lodash");
import ApiClient from "@/services/ApiClient";
import NewCampModal from "./components/newFieldModal.vue";
import DeleteFieldModal from "./components/deleteFieldModal.vue";
export default {
  components: {
    NewCampModal,
    DeleteFieldModal,
  },
  data() {
    return {
      submitLoading: false,
      http: new ApiClient(),
      mandatory: ["id", "name", "email", "phone", "sdkId"],
      currentInfo: null,
      currentFieldToRemove: "",
      newCampDialog: false,
      newCampDialogKey: 0,
      deleteFieldModal: false,
      currentConnectionInfo: { name: "", errorBehaviour: "", cron: "", fields: {} },
      frequencies_csv: [
        {
          name: "Não atualizar",
          value: "not",
        },
        {
          name: "Atualizar via Api",
          value: "not-api",
        },
      ],

      frequencies: [
        {
          name: "Não atualizar",
          value: "not",
        },
        {
          name: "Atualizar via Api",
          value: "not-api",
        },
        {
          name: "15 em 15 minutos",
          value: "*/15 * * * *",
        },

        {
          name: "30 em 30 minutos",
          value: "*/30 * * * *",
        },
        {
          name: "1 em 1 hora",
          value: "0 */1 * * *",
        },
        {
          name: "4 em 4 horas",
          value: "0 */4 * * *",
        },
        {
          name: "8 em 8 horas",
          value: "0 */8 * * *",
        },
        {
          name: "24 em 24 horas",
          value: "0 0 */1 * *",
        },
      ],

      errorBehaviourItems: [
        {
          name: "Ignorar Linha",
          value: "ignore",
        },
        {
          name: "Parar",
          value: "break",
        },
      ],

      dataTypes: [
        {
          name: "Inteiro",
          value: "integer",
        },
        {
          name: "Texto",
          value: "string",
        },
        {
          name: "Data/hora",
          value: "datetime",
        },
        {
          name: "Data",
          value: "date",
        },
        {
          name: "Decimal",
          value: "decimal",
        },
      ],

      dateTimeFormats: [
        {
          format: "YYYY-MM-DDTHH:mm:ssZ",
          description: "ISO 8601 format",
          example: "2023-05-02T14:30:00Z",
        },
        {
          format: "DD/MM/YYYY HH:mm:ss",
          description: "UK/Canada format",
          example: "02/05/2023 14:30:00",
        },
        {
          format: "DD/MM/YYYY HH:mm",
          description: "UK/Canada format",
          example: "02/05/2023 14:30",
        },
        {
          format: "MM/DD/YYYY HH:mm:ss",
          description: "USA format",
          example: "05/02/2023 14:30:00",
        },
        {
          format: "MM/DD/YYYY HH:mm",
          description: "USA format",
          example: "05/02/2023 14:30",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss",
          description: "Database format",
          example: "2023-05-02 14:30:00",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss.SSS",
          description: "UK/Canada format",
          example: "2023-03-08 00:00:00.000000",
        },
      ],

      dateFormats: [
        {
          format: "YYYY-MM-DD",
          description: "ISO 8601 date format",
          example: "2023-05-02",
        },
        {
          format: "DD/MM/YYYY",
          description: "UK/Canada date format",
          example: "02/05/2023",
        },
        {
          format: "MM/DD/YYYY",
          description: "USA date format",
          example: "05/02/2023",
        },
        {
          format: "Do MMMM YYYY",
          description: "Long format",
          example: "2nd May 2023",
        },
      ],
    };
  },
  created() {
    this.getCurrentConnection();
  },
  computed: {
    fieldsList() {
      return Object.keys(this.currentConnectionInfo.fields);
    },
  },
  methods: {
    async submitForm() {
      if (this.$refs.dynamicForm.validate()) {
        let new_fields = _.cloneDeep(this.currentConnectionInfo.fields);

        // percorrer as chaves, para mandar a chave com o dado atualizado
        for (const key in new_fields) {
          if (new_fields[key].new_name) {
            const bkp = new_fields[key];
            new_fields[bkp.new_name] = bkp;
            delete new_fields[key];
          }
        }

        this.currentConnectionInfo.id = this.$route.params.id;
        this.currentConnectionInfo.fields = new_fields;
        this.currentConnectionInfo.cron = this.currentConnectionInfo.cron
          ? this.currentConnectionInfo.cron
          : null;

        try {
          this.submitLoading = true;
          const res = await this.http.post(
            "dbConfiguration/editConnection",
            this.currentConnectionInfo
          );

          console.log(res);
          this.$toast.success("Conexão atualizada com sucesso!");
          this.$router.push({ name: "primaryDataIndexPage" })
          this.submitLoading = false;
        } catch (error) {
          this.$toast.error("Erro ao atualizar conexão");
          this.submitLoading = false;
        }

        console.log("submit", this.currentConnectionInfo);
      }

      //TODO POST COM O OBJETO DA CONEXAO INTEIRO, INCLUSIVE FIELDS PARA /api/dbConfiguration/editConnection
    },

    createNewField(infos) {
      this.newCampDialog = false;
      this.newCampDialogKey++;

      // criar novo objeto para ser inserido no objeto pai
      let new_data = {
        origins: [infos.name],
        type: infos.dataType,
        format: infos.format,
      };

      // inserir novo campo criado no objeto pai
      this.currentConnectionInfo.fields[infos.name] = new_data;

      // resetar o campo de fields para atualizar o computed list
      const bkp = this.currentConnectionInfo.fields;

      this.currentConnectionInfo.fields = null;
      this.currentConnectionInfo.fields = bkp;
    },

    setFieldToRemove(field) {
      this.currentFieldToRemove = field;
      this.deleteFieldModal = true;
    },

    removeField(field) {
      delete this.currentConnectionInfo.fields[field];
      this.deleteFieldModal = false;
    },

    async getCurrentConnection() {
      const res = await this.http.get(`dbConfiguration/${this.$route.params.id}`);

      this.currentInfo = res;
      this.currentConnectionInfo.name = res.name;
      this.currentConnectionInfo.errorBehaviour = res.errorBehaviour;
      this.currentConnectionInfo.fields = await JSON.parse(res.fields);

      console.log("fields");
      console.log(this.currentConnectionInfo.fields);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;

    &__header {
      margin-bottom: 2rem;
      span {
        font-weight: 700;
        font-size: 0.9em;
        line-height: 15px;
        letter-spacing: 0.15px;
        text-transform: uppercase;
      }
    }

    &__content {
      .form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }

      .table {
        margin-top: $z-s-2;

        &__header,
        &__body {
          &__line {
            display: flex;
            align-items: center;
            gap: $z-s-1;
            margin-bottom: 0.7rem;

            .camp-name {
              span {
                font-size: 0.85rem;
                font-weight: 500;
              }
            }
            & > div {
              flex: 1 1 300px;
              width: 100%;

              .text-header {
                font-weight: 700;
                font-size: 0.85em;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
              }

              &:nth-child(2) {
                flex: 3 1 500px;
              }
            }
            @media (max-width: 750px) {
              & > div {
                width: auto;
                span {
                  font-size: 0.85rem;
                  font-weight: 500;
                }
                &:nth-child(2) {
                  flex: 1 1 300px !important;
                }
              }
              .hide {
                display: none;
              }
            }
          }
        }

        .flex-line {
          span {
            font-size: 0.9rem;
          }
        }

        @media (max-width: 750px) {
          .flex-line {
            padding-bottom: 0.6rem;
            &:not(:last-child) {
              border-bottom: 1px solid #c9d6e0;
              margin-bottom: 1rem;
            }

            & > div {
              &:nth-child(1) {
                flex: 0 0 auto;
              }
              &:nth-child(3) {
                flex: 1 1 200px;
              }
            }
          }
        }
        &--action {
          text-align: right;
          max-width: 80%;
          padding: $z-s-1 0;
        }
      }
    }
  }
}
</style>
